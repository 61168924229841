/** @jsxImportSource @emotion/react */
import { useContext,useState } from "react"
import { HeaderContainerStyle, MenuStyle, ButtonMenuStyle } from "./Header.css"
import { ExplorerContext } from "../ExplorerContext"
import { UilBars, UilTimes } from '@iconscout/react-unicons'
import SourcefulBolt from "../Icons/SourcefulBolt"


export const Header = () => {


    const [menuOpen, setMenuOpen] = useState(false)
    const {infoBarOpen} = useContext(ExplorerContext)
    return <div css={HeaderContainerStyle(infoBarOpen)}>
      <a href="/" className="logo">
        <h1>
          <SourcefulBolt />
        </h1>
      </a>
      <button css={ButtonMenuStyle(menuOpen)} onClick={()=>{setMenuOpen(!menuOpen)}}>{menuOpen ? <UilTimes /> : <UilBars/>}</button>
      <ul css={MenuStyle(menuOpen)}>
        <li><a href="https://rakwireless.kckb.st/43924df2">Shop</a></li>
        <li><a href="https://docs.srcful.io/">Docs</a></li>      
        <li><a href="https://support.sourceful.energy/">Support</a></li>
        <li><a href="https://discord.gg/srcful">Community</a></li>
      </ul>
    </div>

}