/** @jsxImportSource @emotion/react */
import Skeleton from "react-loading-skeleton"
import { ProductionBoxStyles, ContentFooterLinks,ScopesButtonGroupStyle, SunIconStyle } from "../InfobarContent.css"
import { InfoSectionStyle, OnlineStyle, OfflineStyle, UpdatedStyle, IconContainerStyle } from "../../BarStyle.css"
import ProductionGraph from "../../ProductionGraph/ProductionGraph"
import { Tooltip } from "react-tooltip"
import Settings from "../Settings"
import InfoIcon from "../../Icons/InfoIcon"
import { useQuery } from "@tanstack/react-query"
import { useContext, useState } from "react"
import { formatISO  } from "date-fns"
import { ExplorerContext } from "../../ExplorerContext"
import Expandable from "../Expandable"
import SunIcon from "../../Icons/SunIcon"
import { truncateString } from "../InfobarContent"
import CopyToClipboard from "../CopyToClipboard"
import { cleanDerSn, isOnline } from "./DerHelper"
import { UilExpandArrowsAlt } from '@iconscout/react-unicons'
import { Link } from "react-router-dom"


const skeletonMargin = (bottom) => { return {"marginBottom":bottom}}
const numberOfMsInADay = 86400000
const scopes = [
    {
        startDateFn:() => {
            const date = formatISO(new Date(new Date() - (30 * numberOfMsInADay)), { representation: 'date' })
            return date
        },
        key: "30d",
        displayName: "30 days",
        chartType: "Bar",
        tooltipFormat: "yyyy-MM-dd",
    },
    {
        startDateFn:() => {
            return formatISO(new Date(new Date() - (7*numberOfMsInADay)))
        },
        key: "7d",
        displayName: "7 days",
        resolution: "1h"
    },
    {
        startDateFn:() => {
            return formatISO(new Date(new Date() - (1*numberOfMsInADay)))
        },
        displayName: "24 hours",
        key: "24h",
        resolution: "10m"
    }
]
const SolarProduction = ({ gatewayRequest, ders, background }) => {

    const [activeScope, setActiveScope] = useState("24h")
    const [showValidationData, setShowValidationData] = useState(localStorage?.getItem("showValidationData") ? JSON.parse(localStorage?.getItem("showValidationData")) : false)
    const { loadHistogramProductionData, loadDailykWhProductionData } = useContext(ExplorerContext)
    const {data, isRefetching, isSuccess} = useQuery({
        queryKey: ["proddata", gatewayRequest.data?.id ,activeScope, showValidationData],
        enabled: gatewayRequest.isSuccess,
        queryFn: async () => {
            const tz = gatewayRequest.data.timeZone
            const includeNullData = isHardware() ? true : false;
            if (selectedScope.key === "30d") {
                return await loadDailykWhProductionData(ders.map(d => d.sn), gatewayRequest.data.id, selectedScope.startDateFn(), tz)
            }
            else {
                let validationIds = null;
                if(ders.some(c => c.validatedBy) && showValidationData){
                    validationIds = ders.filter(x => x.validatedBy).map(x => x?.validatedBy[0])
                }
                return await loadHistogramProductionData(ders.map(d => d.sn), gatewayRequest.data.id, selectedScope.startDateFn(), selectedScope.resolution, tz, includeNullData, validationIds)
            }
        },
        refetchInterval: 30000
    })
    const isHardware = () => {
        return gatewayRequest.data?.typeOf === "Hardware" ?? true;
    }
    const selectedScope = scopes.find(x => x.key === activeScope);

    const isGwOnline = isOnline(data?.derData.solar?.latest,isHardware() ? 10 : 60)

    return <div css={[InfoSectionStyle, background]}>
        <h3><SunIcon style={SunIconStyle} />Solar {!data ? <></> : isGwOnline ? <span css={OnlineStyle}>Online</span> : <span css={OfflineStyle}>Offline</span>}</h3>
        <span css={UpdatedStyle}>Updated: {data?.derData.solar?.latest ? new Date(data.derData.solar.latest.ts).toLocaleString("sv") : "-"}</span>

        <div css={IconContainerStyle}>
            {ders.some(c => c.validatedBy) && <Settings showValidationData={showValidationData} showValidationDataCallback={(show) => {
                localStorage.setItem("showValidationData", show)
                setShowValidationData(show)
            }} />}

            <Link className="expandedview hideInMobile" to="details/solar"> <UilExpandArrowsAlt /></Link>
        </div>

        <div css={ProductionBoxStyles}>
            <div>
                <Tooltip id="currentPower" place={"top-start"} noArrow content="Current power production" />
                <h4 data-tooltip-id="currentPower"><span className="hideInMobile">Current </span>Power <InfoIcon /></h4>
                <p className={isRefetching ? "refetching" : null}>{
                    isGwOnline ?
                        `${((data.derData.solar?.latest?.power ?? 0) / 1000).toFixed(2)} kW` :
                        data ? "-" : <Skeleton style={skeletonMargin("0px")} inline={true} width={"75px"} />
                }
                </p>
            </div>
            <div>
                <Tooltip id="today" place={"top-start"} noArrow html={`The amount of kWh this gateway has produced<br/>since 00:00 in the ${gatewayRequest?.data?.timeZone} timezone.`} />
                <h4 data-tooltip-id="today">Today <InfoIcon /></h4>
                <p>{data?.derData.solar ? `${data.derData.solar.today} kWh` : <Skeleton style={skeletonMargin("0px")} inline={true} width={"75px"} />}</p>
            </div>
        </div>

        {isSuccess ?
            <>
                <div css={ScopesButtonGroupStyle}>
                    {scopes.map(s => <button key={s.key} className={activeScope === s.key ? "active" : null} onClick={() => setActiveScope(s.key)}>{s.displayName}</button>)}
                </div>
                <ProductionGraph 
                    datasets={data?.derData ? 
                        Object.keys(data.derData).filter(k => k.startsWith("DER")).map(x => 
                            data.derData[x].dailykWh ? data.derData[x].dailykWh : data.derData[x].histogram
                        ) 
                        : null}
                    validationData={data?.derData.validationData?.histogram}
                    type={selectedScope.chartType}
                    tooltipFormat={selectedScope.tooltipFormat}
                    timeZone={gatewayRequest.data.timeZone}
                    startTime={selectedScope.startDateFn()}
                    colorMode={isHardware(gatewayRequest.data) ? "hardware" : "software"}
                />
            </>
            :
            <Skeleton height={"167px"} style={skeletonMargin("25px")} /> 
        }
        {ders && ders.some(c => c) 
            &&
                <Expandable>
                    {ders.map((der, idx) => (
                    <><h3 title={der.lastSeen}>DER{ders.length > 1 ? `-${idx+1}` : null} metadata</h3>
                    <ul css={[ContentFooterLinks]}>
                        <li>
                            Make: <span>{der.meta?.make ?? "Unknown"}</span>
                        </li>
                        <li>
                            Rated Power: <span>{der.meta?.nominalPower ? 
                                der.meta?.nominalPower/1000+ "kW" : 
                                "Unknown"
                            }</span>
                        </li>
                        <li>
                            Datapoints (10m): <span>{der.meta?.dataPoints ?? "-"}</span>
                        </li>
                        <li>
                            Sn: <span className={`derid${idx+1}`}>{truncateString(cleanDerSn(der.sn))}</span><CopyToClipboard text={der.sn} copiedClass={`deridCopied${idx+1}`} />
                        </li>
                    </ul>
                    </>
                ))}
                
                </Expandable>
        }
    </div>
}


export default SolarProduction