/** @jsxImportSource @emotion/react */
import { InfoSectionStyle, OfflineStyle, OnlineStyle, UpdatedStyle } from "../../BarStyle.css";
import { BatteryIconStyle, ContentFooterLinks, ProductionBoxStyles } from "../InfobarContent.css";
import BatteryIcon from '../../Icons/BatteryIcon';
import { useQuery } from "@tanstack/react-query";
import { ExplorerContext } from "../../ExplorerContext";
import { useContext } from "react";
import { truncateString } from "../InfobarContent";
import CopyToClipboard from "../CopyToClipboard";
import Expandable from "../Expandable";
import { cleanDerSn, isOnline } from "./DerHelper";
import BatteryGraph from "../../ProductionGraph/BatteryGraph";
import { formatISO } from "date-fns";



const numberOfMsInADay = 86400000
const Battery = ({ gatewayRequest, ders, background }) => {

    const { loadBatteryData } = useContext(ExplorerContext)

    const {data, isSuccess} = useQuery({
        queryKey: ["batterydata", gatewayRequest.data?.id],
        enabled: gatewayRequest.isSuccess,
        queryFn: async () => {
          const startDate = formatISO(new Date(new Date() - (1*numberOfMsInADay)))
          return loadBatteryData(ders[0].sn, startDate) //FIX?;
        },
        refetchInterval: 30000
    })

    const online = isOnline(data?.derData?.battery?.latest,30)

    return <div css={[InfoSectionStyle, background]}>
        <h3><BatteryIcon css={BatteryIconStyle} /> Battery {!data ? <></> : online ? <span css={OnlineStyle}>Online</span> : <span css={OfflineStyle}>Offline</span>}</h3>
        <span css={UpdatedStyle}>Updated: {data?.derData.battery?.latest ? new Date(data.derData.battery.latest.ts).toLocaleString("sv") : "-"}</span>
        
        <div css={ProductionBoxStyles}>
            <div>
                <h4>Charge</h4>
                <p>{isSuccess && online ? `${data.derData.battery.latest.soc}%` : "-"}</p>
            </div>
            <div>
                <h4>Power</h4>
                <p>{isSuccess && online ? `${data.derData.battery.latest.power/1000} kW` : "-"}</p>
                <span>{isSuccess && online ? (data.derData.battery.latest.power < 0 ? "Discharging" : "Charging") : "" }</span>
            </div>
        </div>
        {isSuccess && <BatteryGraph data={data} timeZone={gatewayRequest.data.timeZone} />}
        {ders && ders.some(c => c) 
            &&
            <Expandable>
                {ders.map((der, idx) => (
                <><h3 title={der.lastSeen}>DER{ders.length > 1 ? `-${idx+1}` : null} metadata</h3>
                <ul css={[ContentFooterLinks]}>
                    <li>
                        Sn: <span className={`derid${idx+1}`}>{truncateString(cleanDerSn(der.sn))}</span><CopyToClipboard text={der.sn} copiedClass={`deridCopied${idx+1}`} />
                    </li>
                </ul>
                </>
            ))}
            
            </Expandable>
        }
    </div>


}


export default Battery;